import React, { useState } from "react";
import { useEffect } from "react";
import { getLessonCards } from "src/helpers/aws/aws_s3_helper";
import { isSubLessonCompleted } from "src/helpers/aws/aws_user_data";
import { LessonDetails, emptyLessonDetails } from "src/pages/Course/courseData";

export function useGetLessonCards(callback: Function | undefined = undefined) {
    const [lessonCards, setLessonCards] = useState<LessonDetails[]>([]);

    useEffect(() => {
        getLessonCards()
            .then((cards: LessonDetails[] | null) => {
                if (cards) {
                    setLessonCards(cards);
                }
                callback && callback(cards);
            })
            .catch((error) => {
                console.error("Error fetching lesson cards:", error);
                // Optionally set some state to indicate the error
                callback && callback(null);
            });
        // The empty dependency array ensures this effect runs only once after the component mounts.
    }, []);

    return lessonCards;
}

// const lesson = useGetLesson(lessonCards, lessonId);
export function useGetLesson(lessonCards: LessonDetails[] | undefined, lessonId: string | undefined): LessonDetails {
    // If lessonCards or lessonId is not provided, return the empty lesson details immediately
    if (!lessonCards || !lessonId) return emptyLessonDetails;

    // Find the lesson in the provided lesson cards
    const lessonInCards = lessonCards.find(lesson => lesson.details.id === lessonId);
    if (!lessonInCards) return emptyLessonDetails;

    // Return the found lesson or the empty lesson details if not found
    return lessonInCards || emptyLessonDetails;
}

