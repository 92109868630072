export enum LessonLevel {
    Beginner = 'Beginner',
    Intermediate = 'Intermediate',
    Advanced = 'Advanced'
}

export interface LessonData {
    id: string;
    photoUrl?: string; // Optional photo URL for the lesson
    title: string;
    description: string;
    durationInMinutes: number; // Total duration of all subtopics combined
    subtopicCount: number; // Number of subtopics within this lesson
    level?: LessonLevel; // Optional difficulty level of the lesson
    isComingSoon?: boolean; // Optional flag to indicate that this lesson is coming soon
    isCompleted?: boolean; // Optional flag to indicate that this lesson is completed
    progress: number; // Progress as a percentage (0 to 100)
    order: number; // Order of the lesson within the course
}

export interface LessonSubtopic {
    id: string;
    title: string;
    description: string;
    durationInMinutes: number; // Duration of this subtopic
    content: string; // The main content of the subtopic
    order: number; // Order of the subtopic within its lesson
    progress: number; // Progress of this subtopic as a percentage (0 to 100)
    isPaid?: boolean; // Optional flag to indicate that this subtopic is paid
    isComingSoon?: boolean; // Optional flag to indicate that this subtopic is coming soon
    icon?: string; // Optional icon for the subtopic
    isCompleted?: boolean; // Optional flag to indicate that this subtopic is completed
    photoUrl?: string;
}

export interface LessonDetails {
    details: LessonData;
    subtopics: LessonSubtopic[];
}

export const emptyLessonDetails: LessonDetails = {
    details: {
        id: '',
        title: '',
        description: '',
        durationInMinutes: 0,
        subtopicCount: 0,
        progress: 0,
        order: 0,
    },
    subtopics: [],
};
