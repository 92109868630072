import { Auth, I18n } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
export interface StripePricingTableProps {
    lead: string;
    userId: string;
    email: string;
    pricingTableId?: string;
    publishableKey?: string;
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

const StripePricingTable = (props: any) => {

    const pricingTableIdTest = "prctbl_1NyUSwJAbGiTAcRw5aIiupq6";
    const publishableKeyTest = "pk_test_51NyU85JAbGiTAcRwBW9bGy2UkIqfqd45cdSYOkai1VHMbj2gyLLqjyQHiP6rwhH7NvEfAhVLPb2o9TQU3srRxL4500npo2cUFn";

    const pricingTableId = "prctbl_1OJRMKGX711hdFqAw1kRR0Dg";
    const publishableKey = "pk_live_51OJR4aGX711hdFqAiWx4CVsk6GIcKp3L2J0TZkaFgOWbshjzF9K7uuaAVwOxyhfxp0YrbBlk3OxG2raq3Cx4dnd900L6j016GO";

    const [userId, setUserId] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    useEffect(() => {
        Promise.resolve(Auth.currentUserInfo()).then((userInfo: any) => {
            if (userInfo && userInfo.attributes) {
                setUserId(userInfo.attributes.sub);
                setEmail(userInfo.attributes.email);
            }
        });
    }, []);
    return (
        <div>
            <stripe-pricing-table
                pricing-table-id={pricingTableId}
                publishable-key={publishableKey}
                client-reference-id={userId}
                customer-email={email}>
            </stripe-pricing-table>
        </div>
    );
};
export default withTranslation()(StripePricingTable);
