import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";

const PageFaqs = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Pages" breadcrumbItem="FAQs" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mt-5">
                    <Col xl={4} sm={6}>
                      <Card>
                        <CardBody className="overflow-hidden position-relative">
                          <div>
                            <i className="bx bx-help-circle widget-box-1-icon text-primary"></i>
                          </div>
                          <div className="faq-count">
                            <h5 className="text-primary">01.</h5>
                          </div>
                          <h5>Can I get a refund?</h5>
                          <p className="text-muted mt-3 mb-0">
                            We can{"'"}t offer refunds since converting the PDF to data tables using Machine Learning (ML) is expensive.
                            Our service goes beyond simple optical character recognition (OCR) to identify, understand, and extract data from your PDFs or Images.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xl={4} sm={6}>
                      <Card>
                        <CardBody className="overflow-hidden position-relative">
                          <div>
                            <i className="bx bx-help-circle widget-box-1-icon text-primary"></i>
                          </div>
                          <div className="faq-count">
                            <h5 className="text-primary">02.</h5>
                          </div>
                          <h5 className="mt-3">How much does iMasterSpanish cost?</h5>
                          <p className="text-muted mt-3 mb-0">
                            Right now the basic starts at $15/mo, standard plan is $30/mo, and premium plan is $60/mo.
                            You get 250 pages plus 10 free pages with the basic plan, 500 pages plus 50 free pages with the standard plan,
                            and 1000 plus 100 bonus pages with the premium plan.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col xl={4} sm={6}>
                      <Card>
                        <CardBody className="overflow-hidden position-relative">
                          <div>
                            <i className="bx bx-help-circle widget-box-1-icon text-primary"></i>
                          </div>
                          <div className="faq-count">
                            <h5 className="text-primary">03.</h5>
                          </div>
                          <h5 className="mt-3">Is my payment information secure on your platform?</h5>
                          <p className="text-muted mt-3 mb-0">
                            Yes, our payment service is secure.
                            We use Stripe to handle payments, and don{"'"}t store your card information. Stripe provides bank-level security.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col xl={4} sm={6}>
                      <Card>
                        <CardBody className="overflow-hidden position-relative">
                          <div>
                            <i className="bx bx-help-circle widget-box-1-icon text-primary"></i>
                          </div>
                          <div className="faq-count">
                            <h5 className="text-primary">04.</h5>
                          </div>
                          <h5 className="mt-3">What methods of payment do you accept?</h5>
                          <p className="text-muted mt-3 mb-0">
                            We accept payments with credit/debit cards like MasterCard, VISA, and American Express, and other methods like
                            iDeal, SOFORT, among others. However, we don{"'"}t accept PayPal or cryptocurrency and have no plans to do so.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col xl={4} sm={6}>
                      <Card>
                        <CardBody className="overflow-hidden position-relative">
                          <div>
                            <i className="bx bx-help-circle widget-box-1-icon text-primary"></i>
                          </div>
                          <div className="faq-count">
                            <h5 className="text-primary">05.</h5>
                          </div>
                          <h5 className="mt-3">How do I cancel my subscription?</h5>
                          <p className="text-muted mt-3 mb-0">
                            You can cancel your subscription anytime. Click on {"'"}Manage my Subscription{"'"} in the sidebar menu of iMasterSpanish, which will take you to Stripe{"'"}s billing portal.
                            Log in with your email, and a link will be sent to you. Click it to access the customer portal where you can download invoices, switch plans, or cancel your subscription.
                            You can also <Link target="_blank" to='https://billing.stripe.com/p/login/dR6dRQctLbUU0LK7ss'>click here</Link>, enter your email, and follow the link sent to you to access the customer portal for managing your subscription.
                          </p>
                        </CardBody>{" "}
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default PageFaqs;
