import { Amplify } from "aws-amplify";
import { format } from "path";
import { Attributes, useContext, useState } from "react"
import { Button } from "reactstrap"
import AppContext from "src/components/Hooks/createContext";
import { Language, convertTextToSpeech } from "src/helpers/aws/aws_amplify_predictions"
import AuthenticatedWrapper from "src/pages/Account/account";

export enum SpeakingRate {
    xSlow = 0.5,
    slow = 0.8,
    medium = 0.95,
    fast = 1.5,
    xFast = 2,
};

export enum ButtonOptions {
    All,
    Slow,
    Normal,
    Fast,
}

export const DictateTextToSpanish: React.FC<{ text: string, buttonOption: ButtonOptions }> = ({ text, buttonOption }) => {

    const {
        isAuthAttempted: [_, setIsAuthAttempted],
    } = useContext(AppContext)!;

    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isPaused, setIsPaused] = useState<boolean>(false);

    const [showAuthForm, setShowAuthForm] = useState<boolean>(false);

    const evaluateAuthentication = (callback: Function) => Amplify.Auth.currentAuthenticatedUser()
        .then((user: Attributes) => {
            setIsAuthAttempted(true);
            callback(true);
        })
        .catch((error: any) => {
            callback(false)
        });

    function playDialogs(dialogs: string[], index: number = 0, speakingRate: SpeakingRate = SpeakingRate.medium) {
        if (index >= dialogs.length) {
            setIsPlaying(false);
            setIsPaused(false);
            return; // All dialogs have been played
        }

        const language = index % 2 === 0 ? Language.Spanish1 : Language.Spanish2;

        convertTextToSpeech(dialogs[index], language)
            .then((response: Uint8Array) => {
                const audio = new Audio();
                audio.src = URL.createObjectURL(new Blob([response], { type: 'audio/mpeg' }));
                audio.playbackRate = speakingRate;

                audio.onended = () => {
                    playDialogs(dialogs, index + 1); // Play next dialog
                };

                audio.play();
            }).catch((error) => {
                console.error(error);
            });
    }


    const handlePlay = (speakingRate: SpeakingRate) => {
        evaluateAuthentication((isAuthenticated: boolean) => {
            if (!isAuthenticated) {
                setShowAuthForm(true);
                return;
            }

            setIsPaused(false);
            setIsPlaying(true);
            let _text = text.replace(/\//g, " o ");

            function formatDialog(dialog: string): string[] {
                const parts = dialog.split(" - ");
                if (parts.length !== 2) {
                    return [dialog];
                }

                const question = parts[0].trim();
                const answer = parts[1].trim();
                return [question, answer]
            }

            const dialogs = formatDialog(_text);
            playDialogs(dialogs, 0, speakingRate);
        });
    }

    function onModelToggle(isOpen: boolean) {
        setShowAuthForm(isOpen);
    }

    return (
        <>
            {
                showAuthForm &&
                <AuthenticatedWrapper
                    title="Please sign in to play audio and save progress."
                    onModelToggle={onModelToggle} />
            }

            {(buttonOption === ButtonOptions.All || buttonOption === ButtonOptions.Slow) &&
                <Button color="primary"
                    className="ms-2"
                    size="sm"
                    onClick={() => handlePlay(SpeakingRate.xSlow)}>
                    <i key={`${text}_slow`} className={`mdi mdi-tortoise`}></i>
                </Button>
            }
            {(buttonOption === ButtonOptions.All || buttonOption === ButtonOptions.Normal) &&
                <Button color="primary"
                    className="ms-2"
                    size="sm"
                    onClick={() => handlePlay(SpeakingRate.medium)}>
                    <i key={`${text}_normal`} className={`mdi mdi-play`}></i> Play
                </Button>
            }
            {(buttonOption === ButtonOptions.All || buttonOption === ButtonOptions.Fast) &&
                <Button color="primary"
                    className="ms-2"
                    size="sm"
                    onClick={() => handlePlay(SpeakingRate.fast)}>
                    <i key={`${text}_fast`} className={`mdi mdi-horse-variant-fast`}></i>
                </Button>
            }
        </>
    )
}
