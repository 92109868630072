import { Authenticator } from '@aws-amplify/ui-react';
import { Auth, Hub } from 'aws-amplify';
import { use } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AppContext from 'src/components/Hooks/createContext';

const AuthenticatedWrapper = (props: any) => {
  const [modal, setModal] = useState(true);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const {
    isAuthAttempted: [isAuthAttempted, setIsAuthAttempted],
    isAuthSuccess: [, setIsAuthSuccess],
    userDisplayName: [, setUserDisplayName],
    userId: [, setUserId],
  } = useContext(AppContext)!;

  Hub.listen('auth', (data) => {
    setIsAuthAttempted(true);
    const { payload } = data;
    if (payload.event === 'signIn' && payload.data && payload.data.attributes) {
      setUserDisplayName(payload.data.attributes.name);
      setUserId(payload.data.attributes.sub);
      toggle();
    }
  })

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(user);
        setIsAuthenticated(true);
        setIsAuthSuccess(true);
      }).catch((_) => {
        setIsAuthenticated(false)
      });
  }, [isAuthAttempted]);

  const toggle = () => {
    props.onModelToggle(!modal);
    setModal(!modal);
  }

  return (
    <>
      {!isAuthenticated &&

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} className="justify-content-center">
            <div className="text-center w-100">
              {props.title}
            </div>
          </ModalHeader>
          <ModalBody>
            <Authenticator>
              {props.children}
            </Authenticator>
          </ModalBody>
        </Modal>
      }
    </>
  );
};

export default AuthenticatedWrapper;
