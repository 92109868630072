import { all, fork } from "redux-saga/effects"

//Layout
import LayoutSaga from "./layout/saga";

//Calendar
import calendarSaga from "./calendar/saga";

//Chat
import chatSaga from "./chat/saga";

//Invoices
import invoiceSaga from "./invoices/saga";

//Contact
import contactsSaga from "./contacts/saga";

import dashBoardSaga from "./Dashboard/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(invoiceSaga),
    fork(dashBoardSaga),
    fork(contactsSaga),
  ])
}
