import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./store/index";
import { AmazonAIPredictionsProvider } from '@aws-amplify/predictions';

import { Amplify, I18n } from 'aws-amplify';
import awsmobile from "./aws-exports";
import { enAppDict, enStripeDict, esAppDict, esAuthDict, esStripeDict } from "./i18n/dictionaries/dictionaries";
import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import AppContextProvider from "./components/Hooks/context";
TimeAgo.addDefaultLocale(en)

Amplify.configure(awsmobile);
Amplify.addPluggable(new AmazonAIPredictionsProvider());

I18n.putVocabularies({
  'en': Object.assign({}, enAppDict),
  'es': Object.assign({}, esAuthDict, esAppDict)
});

const getId: any = document.getElementById('root');
const root = ReactDOM.createRoot(getId);
root.render(
  <Provider store={configureStore({})}>
    <BrowserRouter>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </BrowserRouter>
  </Provider>
);

