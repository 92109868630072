import React from 'react'
import { Col, Container, Row } from 'reactstrap';


//Import Bloglist Data
import { useNavigate, useParams } from 'react-router-dom';
import { getUrlTitle } from 'src/utils/Utils';
import Lesson from './Lesson';
import { useGetLesson, useGetLessonCards } from '../Hooks/lessonHooks';
import { LessonSubtopic } from 'src/pages/Course/courseData';
import { lessonData } from 'src/pages/Course/lessonData';

const LessonDetails = (props: any) => {

    let navigate = useNavigate();

    let { lessonId } = useParams<{ lessonId: string }>();
    lessonId = lessonId || props.lessonId;

    let { subtopicId } = useParams<{ subtopicId: string }>();
    subtopicId = subtopicId || props.subtopicId;

    const lessonCards = useGetLessonCards();
    const lesson = useGetLesson(lessonCards, lessonId);

    // Ensure lesson.subtopics is not undefined before attempting to find the active subtopic
    const subtopics = lesson.subtopics || [];

    function getActiveSubtopic(subtopics: LessonSubtopic[], subtopicId: string | undefined) {
        return subtopics.find(subtopic => subtopic.id === subtopicId) || subtopics[0] || { id: '', title: '' };
    }

    // Try to find the active subtopic or default to the first subtopic if available
    const activeSubtopic = getActiveSubtopic(subtopics, subtopicId);

    // Function to navigate back to the lessons page
    const goBackToLesson = () => {
        navigate(`/lesson/${lesson.details.id}/${getUrlTitle(lesson.details.title)}`); // Assuming '/lessons' is the path to your lessons page
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col className="text-left mb-3">
                            <button onClick={goBackToLesson} className="btn btn-primary">
                                <i className={`bx bx-left-arrow-alt`}></i> Back to lesson: {lesson.details.title}
                            </button>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xl={12}>
                            <Lesson lessonId={lesson.details.id} subLessonId={activeSubtopic.id} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default LessonDetails;
