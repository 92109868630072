import { useEffect, useState } from "react";
import { LessonSubtopic } from "../Course/courseData";

export interface RenderSubtopicProps {
    subtopic: LessonSubtopic;
    changeSubtopic: (subtopic: LessonSubtopic) => void;
    lesson: {
        details: {
            id: string;
        };
    };
}

const RenderSubtopic: React.FC<RenderSubtopicProps> = ({ subtopic, changeSubtopic, lesson }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);

    useEffect(() => {
        import(`../../assets/images/lessons/${lesson.details.id}/subtopics/${subtopic.id}.jpg`)
            .then((photo: any) => {
                setImageSrc(photo.default); // Set the image source
            })
            .catch((error: any) => {
                console.log("Error loading image:", error);
                // Handle the case when image is not found
                // For example, set a default image
                setImageSrc('path/to/default/image.jpg');
            });
    }, [subtopic.id, lesson.details.id]); // Re-run the effect if subtopic.id or lesson.details.id changes

    return (
        <div className="timeline-box clickable-timeline-box" onClick={() => changeSubtopic(subtopic)}>
            <div className="timeline-date bg-primary text-center rounded">
                <h3 className="text-white mb-0">{subtopic.order}</h3>
            </div>

            <div className="event-content">
                <div className="timeline-text">
                    <h3 className="font-size-18">
                        {subtopic.title}
                    </h3>
                    <p className="mb-0 mt-2 pt-1 text-muted">
                        {subtopic.description}
                    </p>
                    <div className="image-container">
                        {imageSrc && (
                            <img
                                className="card-img img-fluid"
                                src={imageSrc}
                                alt={subtopic.title}
                                style={{
                                    objectFit: 'cover',
                                }}
                            />
                        )}
                        {subtopic.isCompleted && (
                            <div className="completed-banner">
                                <span className="checkmark">&#x2713;</span> {/* Unicode for checkmark */}
                                <span className="completed-text">Completed</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RenderSubtopic;
