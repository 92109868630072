
export class FreeTrialOverError extends Error {
    constructor(message: string) {
        super(message);

        // Set the prototype explicitly, as it's not set automatically in some environments (e.g., when transpiling to ES5)
        Object.setPrototypeOf(this, NotEnougCreditsError.prototype);

        // Optionally, you can set a custom name for your error
        this.name = 'FreeTrialOverError';
    }
}

export class NotEnougCreditsError extends Error {
    constructor(message: string) {
        super(message);

        // Set the prototype explicitly, as it's not set automatically in some environments (e.g., when transpiling to ES5)
        Object.setPrototypeOf(this, NotEnougCreditsError.prototype);

        // Optionally, you can set a custom name for your error
        this.name = 'NotEnougCreditsError';
    }
}

export class SubscriptionRequiredError extends Error {
    constructor(message: string) {
        super(message);

        // Set the prototype explicitly, as it's not set automatically in some environments (e.g., when transpiling to ES5)
        Object.setPrototypeOf(this, SubscriptionRequiredError.prototype);

        // Optionally, you can set a custom name for your error
        this.name = 'SubscriptionRequiredError';
    }
}
