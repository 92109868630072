import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import StripePricingTable from "../../components/Payments/stripePricingTable";
import { withTranslation } from "react-i18next";
import { Container } from "reactstrap";
import React from "react";

const Paywall = (props: any) => {
    const [userId, setUserId] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    useEffect(() => {
        // get authenticated userId
        Promise.resolve(Auth.currentUserInfo()).then((userInfo: any) => {
            if (userInfo && userInfo.attributes) {
                setUserId(userInfo.attributes.sub);
                setEmail(userInfo.attributes.email);
            }
        });
    }, []);

    let pricingTable = <StripePricingTable
        userId={userId}
        email={email}
        lead={"paywall_lead"} />

    if (props.pricingTableOnly) {
        return pricingTable;
    }

    let payWall = (
        <Container fluid>
            {!props.isSubscribed &&
                pricingTable
            }
        </Container>
    );

    if (props.embedded) {
        return payWall;
    }

    return (<>
        <React.Fragment>
            <div className="page-content">
                {payWall}
            </div>
        </React.Fragment>
    </>);
}

export default withTranslation()(Paywall);
