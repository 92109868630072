import React, { useContext, useEffect, useState } from "react";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Col, Container, Row } from "reactstrap";

// import common data
import { verifyUsage } from "src/helpers/usage/usage_helper";
import { LessonDetails } from "../Course/courseData";
import { LessonCard } from "../Course/LessonCard";
import { getLessonCards } from "src/helpers/aws/aws_s3_helper";
import AppContext from "src/components/Hooks/createContext";

const Dashboard = () => {
  document.title = "The best way to learn Spanish online - iMasterSpanish";
  const [isSubscriptionStatusChecked, setIsSubscriptionStatusChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const [lessonCards, setLessonCards] = useState<LessonDetails[] | undefined>();

  const {
    isSubscribed: [isSubscribed, setIsSubscribed],
  } = useContext(AppContext)!;

  useEffect(() => {
    setLoading(true);

    // Verify usage
    verifyUsage()
      .then((isSubscribed: boolean) => {
        setIsSubscribed(isSubscribed);
        setIsSubscriptionStatusChecked(true);
        setLoading(false);
      });

    getLessonCards()
      .then((lessonDetails: LessonDetails[] | null) => {
        if (lessonDetails) {
          setLessonCards(lessonDetails);
        }
      }).catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="iMasterSpanish" breadcrumbItem="Master Spanish" /> */}
          <h1 className="text-center">Learn Spanish in just 25 Minutes a Day. Get Started for Free.</h1>
          {/* Show spinner on center of page if no lesson cards */}
          {!lessonCards &&
            <div className="d-flex justify-content-center">
              <span className="font-size-18">Loading....</span>
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          }
          {lessonCards &&
            <>
              <Row>
                <Col sm={12}>
                  <h4 className="mt-2 mb-4">Introductory Lessons</h4>
                  <Row data-masonry='{"percentPosition": true }'>
                    {lessonCards.map(lesson =>
                      <>
                        <Col sm={6} lg={4}>
                          <LessonCard lesson={lesson.details} />
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
              <Row>
                <h4 className="mt-2 mb-4">Intermediate Lessons Coming Soon...</h4>

              </Row>
            </>
          }
        </Container>
      </div >
    </React.Fragment >
  );
};

export default Dashboard;
