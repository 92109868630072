import { Predictions } from "aws-amplify";

export enum Language {
    Spanish = "Mia",
    Spanish1 = "Lupe",
    English = "Salli",
    Spanish2 = "Miguel",
}
// use amplify predictions to convert spanish text to speech
export async function convertTextToSpeech(text: string, language: Language): Promise<Uint8Array> {
    const speechResult = await Predictions.convert({
        textToSpeech: {
            source: {
                text
            },
            voiceId: language,
        }
    });
    return speechResult.audioStream as Uint8Array;
}
