import { Auth, Cache } from "aws-amplify";
import { countCompletedSubLessons, isSubLessonInLessonCompleted, recordUserProgress } from "./aws_s3_helper";

// get cache key for completed lessons
function getLessonCacheKey(lessonId: string): string {
    return `lesson-${lessonId}-completedSubLessons`;
}

function getRewardPointsCacheKey(): string {
    return `rewardPoints`;
}

//
function getCompletedSubLessons(lessonId: string): Set<string> {
    const key = getLessonCacheKey(lessonId);
    console.log("lessons key-", key);
    const completedSubLessonsItems = Cache.getItem(key);
    console.log("completedSubLessonsItems", completedSubLessonsItems);
    if (!completedSubLessonsItems ||
        completedSubLessonsItems.length === 0 ||
        !completedSubLessonsItems[Symbol.iterator]) {
        return new Set();
    }
    return new Set(completedSubLessonsItems);
}

// add completed sub lesson by id for lessonId to cache
function addSubLesson(lessonId: string, subLessonId: string) {
    const key = getLessonCacheKey(lessonId);
    let lessons = getCompletedSubLessons(lessonId);
    lessons = lessons.add(subLessonId);
    Cache.setItem(key, Array.from(lessons));
    console.log("lessons--", Cache.getItem(key));
}

// Get reward points from cache
export function getRewardPoints(): number {
    const key = getRewardPointsCacheKey();
    const rewardPoints = Cache.getItem(key);
    if (!rewardPoints) {
        return 0;
    }
    return rewardPoints;
}

// add reward points to cache, add to current points
export function addRewardPoints(rewardPoints: number) {
    const key = getRewardPointsCacheKey();
    let points = getRewardPoints();
    points = points + rewardPoints;
    Cache.setItem(key, points);
}

function addCompletedSubLessons(lessonId: string, subLessonId: string) {
    addSubLesson(lessonId, subLessonId);
}

// add completed sub lesson by id for lessonId to cache
export async function markSubLessonAsCompleted(lessonId: string, subLessonId: string): Promise<void> {

    try {
        if (!!(await Auth.currentAuthenticatedUser())) {
            // user is logged in, record to s3
            await recordUserProgress(lessonId, subLessonId);
            return;
        }
    } catch (error) {
        console.error('Error adding completed sub lesson:', error);
    }

    let completedSubLessons = getCompletedSubLessons(lessonId);
    addCompletedSubLessons(lessonId, subLessonId);

    console.log("completedSubLessons--", completedSubLessons);
}

// get completed sub lessons count for lessonId from cache
export async function completedSubLessonCount(lessonId: string): Promise<number> {
    // check if user is logged in
    try {
        if (!!(await Auth.currentAuthenticatedUser())) {
            // user is logged in, read from s3
            return countCompletedSubLessons(lessonId);
        }
    } catch (error) {
        console.error('Error adding completed sub lesson:', error);
    }

    const completedSubLessons = getCompletedSubLessons(lessonId);
    console.log("completedSubLessons", completedSubLessons);
    return completedSubLessons.size;
}

// check if sub lesson is completed for lessonId
export async function isSubLessonCompleted(lessonId: string, subLessonId: string): Promise<boolean> {
    const cacheKey = `lesson-${lessonId}-completedSubLessons-${subLessonId}-done`;
    if (Cache.getItem(cacheKey)) {
        return true;
    }
    console.log(cacheKey)
    let isCompleted = false;
    // check if user is logged in
    try {
        if (!!(await Auth.currentAuthenticatedUser())) {
            // user is logged in, read from s3
            isCompleted = await isSubLessonInLessonCompleted(lessonId, subLessonId)
        } else {
            const completedSubLessons = getCompletedSubLessons(lessonId);

            isCompleted = completedSubLessons.has(subLessonId);

        }
    } catch (error) {
        console.error('Error adding completed sub lesson:', error);
    }

    if (isCompleted) {
        Cache.setItem(cacheKey, isCompleted);
    }

    return isCompleted;
}



