import React from "react";
import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const PrivacyPolicy = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Pages" breadcrumbItem="Terms of Service" />
                    <h1>iMasterSpanish Privacy Policy</h1>
                    <p><strong>Last Updated:</strong> October 11, 2023</p>

                    <p>iMasterSpanish ("us," "we," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our website (https://iMasterSpanish.com) and our services.</p>

                    <h2>1. Information We Collect</h2>
                    <p>We may collect the following types of information:</p>
                    <ul>
                        <li><strong>Personal Information:</strong> Name, email, and payment information.</li>
                        <li><strong>Uploaded Documents:</strong> Documents you upload to our service for conversion to Excel or CSV.</li>
                        <li><strong>Non-Personal Information:</strong> Information collected via web cookies to enhance your user experience.</li>
                    </ul>

                    <h2>2. How We Use Your Information</h2>
                    <p>We may use your information for the following purposes:</p>
                    <ul>
                        <li>To provide and maintain our services.</li>
                        <li>To notify you about changes to our services or website.</li>
                        <li>To respond to your inquiries or requests.</li>
                        <li>To improve our services and enhance your user experience.</li>
                        <li>To enforce our Terms of Service and applicable laws.</li>
                    </ul>

                    <h2>3. Information Sharing</h2>
                    <p>We do not sell or rent your personal information to third parties. We may share your information under the following circumstances:</p>
                    <ul>
                        <li>With service providers who assist in the operation of our website and services.</li>
                        <li>When required by law or in response to valid legal requests.</li>
                        <li>To protect our rights, privacy, safety, or property, or that of our users.</li>
                    </ul>

                    <h2>4. Data Security</h2>
                    <p>We take measures to protect your data, but no method of transmission over the internet is completely secure. We cannot guarantee the absolute security of your data.</p>

                    <h2>5. Your Choices</h2>
                    <p>You can manage your personal information by contacting us. You may also unsubscribe from our email communications at any time.</p>

                    <h2>6. Updates to the Privacy Policy</h2>
                    <p>We may update this Privacy Policy to reflect changes in our practices. We will notify you of any material changes via email or by prominently posting a notice on our website.</p>

                    <h2>7. Contact Us</h2>
                    <p>If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:iMasterSpanish@gmail.com">iMasterSpanish@gmail.com</a>.</p>

                    <p>Thank you for using iMasterSpanish!</p>

                </Container>
            </div>
        </React.Fragment >
    );
};

export default PrivacyPolicy;
