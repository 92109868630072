import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import BlogList Data
import {
  blogArticleList,
} from "../../common/data/blog";
import { Link } from "react-router-dom";

const Blog = () => {

  document.title = "Blog List | Ovig - React Admin & Dashboard Template";

  const [search_Menu, setsearch_Menu] = useState(false);

  //Toggle Chat Box Menus
  const toggleSearch = () => {
    setsearch_Menu(!search_Menu);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Blog" breadcrumbItem="Blog List" />
          <Row className="row align-items-center mt-0">
            <Col md={6}>
              <div className="mb-3">
                <h5 className="card-title">
                  Bank Statement Converter AI Blog{" "}
                  <span className="text-muted fw-normal ms-2">({blogArticleList.length})</span>
                </h5>
              </div>
            </Col>
          </Row>
          {/* <!-- end row --> */}
          <Row className="">
            {blogArticleList.reverse().map((item, key) => (
              <Col xl={4} sm={6} key={key}>
                <Card>
                  <div className="">
                    <img src={item.img} alt="" className="img-fluid" />
                  </div>
                  <CardBody>
                    <p className="text-muted mb-2">{item.date}</p>
                    <h5 className="text-body">
                      <Link className="text-dark" to={`/articles/${item.id}`}>
                        {item.title}
                      </Link>
                    </h5>
                    <p className="mb-0 font-size-15">{item.desc}</p>
                    <div className="mt-3">
                      <Link className="align-middle font-size-15" to={`/articles/${item.id}`}>
                        Read more <i className="mdi mdi-chevron-right"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Blog;
