import React, { useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    CardHeader,
    Row,
    Col,
    Button,
} from 'reactstrap';

import JSConfetti from 'js-confetti'

import { PracticeQuestion } from 'src/pages/Course/lessonData';
import { ButtonOptions, DictateTextToSpanish } from './DictateTextToSpanish';

// import audio from assets/audio/correct.mp3
import correct from 'src/assets/audio/correct.mp3';
import incorrect from 'src/assets/audio/incorrect.mp3';
import { use } from 'i18next';
import { useNavigate } from 'react-router';
import { Language } from 'src/helpers/aws/aws_amplify_predictions';

interface PracticeSectionProps {
    goBackToLessons: Function
    practice: {
        instructions: string;
        maxPoints: number;
        questions: PracticeQuestion[];
    };
    selectedAnswers: Record<string, string>;
    validation: Record<string, boolean>;
    handleRadioChange: (questionId: string,
        selectedOption: string,
        correctAnswer: string | Set<string>,
        selectedOptionExplanation: string) => void;
    markCompleted: Function;
}

const QUESTIONS_PER_PAGE = 1; // Number of questions to display per page

const PracticeSection: React.FC<PracticeSectionProps> = ({
    goBackToLessons,
    practice,
    selectedAnswers,
    validation,
    handleRadioChange,
    markCompleted }) => {
    const [currentPage, setCurrentPage] = useState(0);

    const [errorMessage, setErrorMessage] = useState('');

    // Calculate the total number of pages
    const pageCount = Math.ceil(practice.questions.length / QUESTIONS_PER_PAGE);

    const navigate = useNavigate();

    // Slice the questions to only get those for the current page
    const currentQuestions = practice.questions.slice(
        currentPage * QUESTIONS_PER_PAGE,
        (currentPage + 1) * QUESTIONS_PER_PAGE
    );

    // Calculate the total number of points
    const points = Object.values(validation).filter((isValid) => isValid).length * 10;

    // Helper function to check if all questions on the current page are answered correctly
    const areAllQuestionsCorrect = (questions: any, selectedAnswers: any, validation: any) => {
        return questions.every((question: any) => {
            const questionId = `question-${question.question}`;
            const isAnswered = selectedAnswers.hasOwnProperty(questionId);
            const isCorrect = validation[questionId];
            return isAnswered && isCorrect;
        });
    };


    // Update the current page
    const setPage = (page: number) => {
        // If we're trying to go to the next page, make sure all current questions are correct
        if (page > currentPage && !areAllQuestionsCorrect(currentQuestions, selectedAnswers, validation)) {
            setErrorMessage('Please answer all questions correctly before proceeding to the next page.');
            return;
        } else {
            setErrorMessage(''); // Clear error message when no errors
        }

        if (page >= 0 && page < pageCount) {
            setCurrentPage(page);
        }
    };

    const allCorrect = points === practice.maxPoints;

    const jsConfetti = new JSConfetti()

    if (allCorrect && pageCount === currentPage + 1) {
        markCompleted();

        jsConfetti.addConfetti({
            confettiColors: [
                '#FF0000', // Red
                '#FF6347', // Tomato Red
                '#FF4500', // OrangeRed
                '#32CD32', // LimeGreen
                '#008000', // Green
                '#006400', // DarkGreen
            ],
        })
    }

    // calculate screen size
    const [screen, setScreen] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    // Utility function to shuffle an array
    function shuffleArray(array: any[]) {
        // for (let i = array.length - 1; i > 0; i--) {
        //     const j = Math.floor(Math.random() * (i + 1));
        //     [array[i], array[j]] = [array[j], array[i]];
        // }
        return array; // Return the shuffled array
    }

    return (
        <Card className="mb-3">
            <CardHeader>
                <CardTitle tag="h2">Practice</CardTitle>
                <p>{practice.instructions}</p>
                <p>Points: {points} </p>
                {allCorrect && (
                    <div className="text-center text-success">
                        <h4>Congratulations! 🎉🎉 You've answered all questions correctly! 🥳🥳</h4>
                        <button onClick={() => {
                            // remove the last two path segments
                            const path = window.location.pathname.split('/').slice(0, -2).join('/');
                            navigate(path);
                        }} className="btn btn-primary">
                            <i className={`bx bx-left-arrow-alt`}></i> Back to Lessons
                        </button>
                    </div>
                )}
            </CardHeader>
            <CardBody>
                {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                <Row className="justify-content-center">
                    <Col md={7}>
                        {currentQuestions.map((question, index) => (
                            <FormGroup key={`form-question-${question.question}`}>
                                <Label for={`question-${index}`}>{question.question}</Label>
                                {shuffleArray(question.options).map((option, _) => {
                                    const questionId = `question-${question.question}`;
                                    const isSelected = selectedAnswers[questionId] === option.text;
                                    return (
                                        <Row className='mb-2'>
                                            <Col md={8} className="text-center">
                                                <FormGroup check key={questionId}>
                                                    <Label className={`custom-radio ${isSelected ? (validation[questionId] ? "text-success correct" : "text-danger") : ""}`}>
                                                        <Input
                                                            type="radio"
                                                            id={questionId}
                                                            name={question.question}
                                                            value={option.text}
                                                            onChange={() => handleRadioChange(questionId, option.text, question.answer, option.explanation)}
                                                        />
                                                        <span className={`custom-radio-btn ${isSelected ? (validation[questionId] ? "correct-option" : "wrong-option") : ""}`}></span>
                                                        {option.text}
                                                    </Label>
                                                    {
                                                        isSelected && (
                                                            <div className={validation[questionId] ? "text-success" : "text-danger"}>
                                                                {validation[questionId] &&
                                                                    <>
                                                                        <small>{option.explanation}</small>
                                                                    </>
                                                                }
                                                                {!validation[questionId] &&
                                                                    <p className="mt-1">
                                                                        <small>{option.explanation}</small>
                                                                        <audio src={incorrect} autoPlay />
                                                                    </p>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <DictateTextToSpanish
                                                    text={option.text}
                                                    buttonOption={ButtonOptions.Normal} />
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </FormGroup>
                        ))}
                    </Col>

                </Row>
                {/* Center row content reactstrap */}
                <Row className="justify-content-center">
                    <Button color="primary"
                        className="mb-3 text-center"
                        size="lg"
                        style={{ height: '100%', width: '50%' }}
                        onClick={() => setPage(currentPage + 1)}>
                        Next <i key={`next`} className={`mdi mdi-chevron-double-right`}></i>
                    </Button>

                    <Button color="primary"
                        className="mb-3 mx-2 text-center"
                        size="lg"
                        style={{ height: '100%', width: '50%' }}
                        onClick={() => setPage(currentPage - 1)}>
                        <i key={`back`} className={`mdi mdi-chevron-double-left`}></i>Back
                    </Button>
                </Row>
                {/* Pagination Controls */}

            </CardBody>
        </Card >
    );
};

export default PracticeSection;
