import { executeCall } from "../aws/aws_apigateway_helpert";

export const verifyUsage = async function (): Promise<any> {
    try {
        await executeCall("/imasterspanish/verify-usage", {});
        return true;
    } catch (error) {
        console.warn("error", error);
        return false;
    }
}
