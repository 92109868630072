import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import *  as converter from '@aws-sdk/util-base64-browser';
import { Auth } from 'aws-amplify';

export function makeCamelCase(obj: object | undefined, keys?: string[]) {
    if (!obj) return undefined;
    const newObj = {};
    const keysToRename = keys ? keys : Object.keys(obj);
    keysToRename.forEach(key => {
        if (obj.hasOwnProperty(key)) {
            // change the key to camelcase.
            const camelCaseKey = key.charAt(0).toLowerCase() + key.substr(1);
            // @ts-ignore
            Object.assign(newObj, { [camelCaseKey]: obj[key] });
        }
    });
    return newObj;
}

/**
 * Given an array of object, call makeCamelCase(...) on each option.
 */
export function makeCamelCaseArray(objArr: object[] | undefined, keys?: string[]) {
    if (!objArr) return undefined;
    return objArr.map(obj => makeCamelCase(obj, keys));
}

/**
 * Converts blob to array buffer
 */
export function blobToArrayBuffer(blob: Blob): Promise<Uint8Array> {
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.onload = _event => {
            res(reader.result as Uint8Array);
        };
        reader.onerror = err => {
            rej(err);
        };
        try {
            reader.readAsArrayBuffer(blob);
        } catch (err) {
            rej(err); // in case user gives invalid type
        }
    });
}

function toCSV(tableData: any) {
    const csvRows = [];
    for (let row of tableData.table) {
        const rowData = row.map((cell: any) => `"${cell.text.replace(/"/g, '""')}"`).join(',');
        csvRows.push(rowData);
    }
    const csvString = csvRows.join('\r\n');
    return new Blob([csvString], { type: 'text/csv' });
}

function toExcel(tableData: any) {
    const ws = XLSX.utils.aoa_to_sheet(tableData.table.map((row: any) => row.map((cell: any) => cell.text)));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    return wb;
}

export function downloadCSV(tableData: any, fileName: string,) {
    saveAs(toCSV(tableData), `iMasterSpanish.com_${fileName}.csv`);
}

export function downloadExcel(tableData: any, fileName: string,) {
    const wb = toExcel(tableData)
    XLSX.writeFile(wb, `iMasterSpanish.com_${fileName}.xlsx`);
}

export async function checkUserAuthentication(): Promise<boolean> {
    try {
        await Auth.currentAuthenticatedUser();
        return true;
    } catch (error) {
        return false;
    }
}

/**
 * Encodes a string into URL-safe base64 format.
 * @param input The string to encode.
 * @returns The URL-safe base64 encoded string.
 */
export function urlSafeBase64Encode(input: string): string {
    const utf8Bytes = new TextEncoder().encode(input);
    return converter.toBase64(utf8Bytes);
}

/**
 * Decodes a URL-safe base64 encoded string.
 * @param input The URL-safe base64 encoded string.
 * @returns The decoded string.
 */
export function urlSafeBase64Decode(input: string): string {
    try {
        const decodedBytes = converter.fromBase64(input);
        return new TextDecoder().decode(decodedBytes);
    } catch (error) {
        console.error(error);
    }
    return input;
}

export function extractFileExtension(src: string) {
    let fileType = "image";
    if (src) {
        fileType = src.split('.').pop()?.toLocaleLowerCase() || fileType;
    }
    return fileType;
}

export function extractFilename(key: string, spaceOutName: boolean) {
    if (!key || typeof key !== 'string') {
        return '';  // Return an empty string for null, undefined, or non-string inputs
    }

    const delimiter = '--_';

    // Find the position of the delimiter and extract everything after it
    const startPos = key.indexOf(delimiter);
    if (startPos === -1) {
        return key;  // Return an empty string if the delimiter is not found
    }

    const filenameWithExt = key.substring(startPos + delimiter.length);

    // Replace underscores with spaces
    const filename = spaceOutName ? filenameWithExt.replace(/_/g, ' ') : filenameWithExt;

    // Split by dot and exclude the extension
    const nameParts = filename.split('.');
    if (nameParts.length <= 1) {
        return filename;  // Return the filename as is if there's no dot
    }

    nameParts.pop();  // Remove the last part (extension)
    return nameParts.join('.');
}


export const getUrlTitle = (title: string) => {
    return title.toLowerCase().replace(/ /g, '-');
}

