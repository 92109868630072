import FeatherIcon from "feather-icons-react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../Hooks/createContext";
import { getRewardPoints } from "src/helpers/aws/aws_user_data";
import { layoutTheme } from "src/constants/layout";
import { UncontrolledTooltip } from "reactstrap";

interface RewardPointsProps {
    layoutMode: string;
}

const RewardPoints = ({ layoutMode }: RewardPointsProps) => {

    const {
        rewardPoints: [rewardPoints, setRewardPoints],
    } = useContext(AppContext)!;

    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (rewardPoints < 1) {
            const rewardPoints = getRewardPoints();
            setRewardPoints(rewardPoints);
        }
    }, [rewardPoints]);

    useEffect(() => {
        if (rewardPoints > -10) {
            setAnimate(true);
            const timer = setTimeout(() => {
                setAnimate(false);
            }, 500); // Duration of the animation
            return () => clearTimeout(timer);
        }
    }, [rewardPoints]);

    return (
        <div className="d-sm-inline-block">
            {/* Show tooltip */}
            <UncontrolledTooltip placement="bottom" target="reward-points">
                App gifts coming soon!
            </UncontrolledTooltip>
            <button type="button" className="btn header-item position-relative mx-4" id="reward-points"
                onClick={() => { }}>
                {layoutMode === layoutTheme["DARKMODE"] ? (
                    <FeatherIcon icon="gift" className={`icon-lg layout-mode-light ${animate ? 'animated-bounce' : ''}`} />
                ) : (
                    <FeatherIcon icon="gift" className={`icon-lg layout-mode-dark ${animate ? 'animated-bounce' : ''}`} />
                )}

                <span className="position-absolute my-2 badge rounded-circle p-1">
                    {
                        rewardPoints > 0 ?

                            <span className={`badge bg-success font-size-14 rounded-pill ${animate ? 'animated-bounce' : ''}`}>
                                {rewardPoints}</span>
                            : <span className={`badge bg-danger font-size-14 rounded-pill ${animate ? 'animated-bounce' : ''}"`}>
                                {rewardPoints}</span>
                    }
                </span>
            </button>
        </div >
    );
}

export default RewardPoints;
