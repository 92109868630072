import React, { useEffect, useState } from "react";

//import components
import SidebarContent from "./SidebarContent";
import { verifyUsage } from "src/helpers/usage/usage_helper";

const Sidebar = (props: any) => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
