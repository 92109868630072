import image1 from "../../assets/images/blog/d9697ed3-623c-470e-9505-159d5b05efa4_inspire.png";
import image2 from "../../assets/images/blog/blog_post_2_main_image.png";
import image3 from "../../assets/images/blog/amol-tyagi-0juktkOTkpU-unsplash.jpg";
import image4 from "../../assets/images/blog/why_you_need_bank_statement_converter_ai.png";
/*
ChatGPT blog

Write me a blog post follow the following guidelines.

Topic:
How we secure your data

Instructions:
Optimize it for SEO, here are details. Make sure each paragraph is has the following className mb-4, also this is in react ts.
Make it sound authentic and real. Generate a title as well. Make sure it is in html format. Each paragraph should
be wrapped with a html p tag.

Background:
We take security of your data and information very seriously.

Here we detail how we secure your information.

First and foremost, we require you to have an account to use bank statement converter AI.
That provides you with several security benefits, it makes sure that when we store your
document for processing, it is encrypted with your account details.

We encrypt your data both in flight (explain what this means like I'm 5), and during rest (explain what this means).

We also delete any data you upload or we generate from analyzing your document in 24 hours automatically.

A little about encyption, because your documents are encrpted, that means, even if someone has a
link to your document, without your account information, they cannot access it.

Each URl generate to access your document must be signed, that is each request must include a signature. To calculate a signature, we first concatenate select request elements to form a string, referred to as the string to sign. We then use a signing key to calculate the hash-based message authentication code (HMAC) of the string to sign.

Our storage service will then re-create the signature by using the authentication information that is contained in the request. If the signatures match, it processes your request; otherwise, the request is rejected.

If you want to learn more about how we secure your data, don't hesitate to contact us at
iMasterSpanish@gmail.com

*/

export const blogArticleList = [
    {
        id: "1",
        date: "14 Oct, 2023",
        title: "Building Bank Statement Convert AI: A Journey through AWS, Stripe, and Lambda",
        desc: "I talk about what it took to build this website, challenges faced, and what lies ahead",
        img: image1,
        content: `<p className="mb-4">
        Hey there, folks! Today, I'm thrilled to take you on a behind-the-scenes tour of how I brought Bank Statement Convert AI to life. This nifty tool is all about making your life easier by effortlessly extracting data from your bank statements. But it wasn't all smooth sailing; I had to dive headfirst into AWS Textract, AWS Amplify, Stripe, and AWS Lambda to turn this idea into a reality.
        </p>
        <p className="mb-4">
        <strong>AWS Textract: Unleashing the Data Wizardry</strong>
        </p>
        <p className="mb-4">
        So, where did it all start? Well, imagine this: a bunch of bank statements piled up, and you need that data pronto. Enter AWS Textract! It's like the Gandalf of data extraction but with its quirks. You see, Textract's DocumentAnalysis API only plays nice with images. That meant I had to do a bit of magic by converting each PDF page into an image before sending it off for analysis. Sure, there was a more complex option involving the Start Analysis API, but who needs complications?
        </p>
        <p className="mb-4">
        <strong>AWS Amplify: Hosting Made Easy</strong>
        </p>
        <p className="mb-4">
        Now, onto the hosting front! I wanted Bank Statement Convert AI to be smooth as silk for you guys, and that's where AWS Amplify came to the rescue. This awesome platform took care of all the hosting jazz, leaving me free to focus on what really matters - the core functionality of the tool. With Amplify, I could effortlessly handle things like authentication, APIs, and front-end hosting, saving me a truckload of development headaches.
        </p>
        <p className="mb-4">
        <strong>Stripe: Where Payments Meet Simplicity</strong>
        </p>
        <p className="mb-4">
        Of course, we needed a way to keep the lights on around here. So, say hello to Stripe! This payment-processing marvel made monetizing the app a breeze. Integrating Stripe into Bank Statement Convert AI was like a walk in the park. It not only ensured secure payment handling but also gave users a hassle-free and trustworthy experience.
        </p>
        <p className="mb-4">
        <strong>AWS Lambda: The Powerhouse in the Shadows</strong>
        </p>
        <p className="mb-4">
        Behind the scenes, we had AWS Lambda, our trusty sidekick handling all things backend. Lambda is like the unsung hero of serverless computing. Whether it was crunching through uploaded bank statements, managing user accounts, or juggling data, Lambda had our back. It's like that superhero who never needs sleep and can scale up to handle anything - truly remarkable!
        </p>
        <p className="mb-4">
        <strong>Wrap-Up: From Idea to Reality</strong>
        </p>
        <p className="mb-4">
        In a nutshell, the journey of building Bank Statement Convert AI was a rollercoaster ride of ups and downs. From the "a-ha" moment of the initial concept to a fully functional tool, it took dedication and a willingness to tango with various tech flavors. AWS Textract, AWS Amplify, Stripe, and AWS Lambda were our main ingredients in this recipe for success.
        </p>
        <p className="mb-4">
        This project isn't just about innovation; it's about making life simpler. Bank Statement Convert AI is the real deal, making the lives of individuals and businesses alike a whole lot easier.
        </p>
        <p className="mb-4">
        As the world of fintech keeps on spinning, there are endless possibilities for creating transformative solutions. Building Bank Statement Convert AI was just our first step, and we're excited to keep enhancing and expanding it, making it even more powerful for you in the future.
        </p>
        <p className="mb-4">
        And there you have it, folks! Bank Statement Convert AI is all about merging cutting-edge tech with practicality, showing the world that innovation doesn't have to be a buzzword. Our journey has been one wild ride, and we're pumped to see how this tool evolves and continues to make a difference in your lives.
        </p>`
    },
    {
        id: "2",
        date: "14 Oct, 2023",
        title: "How to Use Bank Statement Converter AI",
        desc: "A step-by-step guide to use our Bank Statement Converter AI, making data extraction effortless and efficient.",
        img: image2,
        content: `<p className="mb-4">
        Welcome to our Bank Statement Converter AI tool! In this step-by-step guide, we'll walk you through how to
        make the most of our tool, from uploading your financial statements to extracting valuable data. Whether you
        have PDFs, JPGs, JPEGs, or PNGs, we've got you covered.
    </p>

    <p className="mb-4">
        First, head to the home tab on our platform. This is where the magic begins. Once you're there, drag and drop your document on
        the upload box or click the box to upload. You can choose the document you want to convert or extract into data tables.
    </p>

    <p className="mb-4">
        After your document uploads, you'll be redirected to the analysis page. Here, our AI-powered tool will work its
        wonders, transforming your uploaded document or image into neat and organized data tables.
    </p>

    <p className="mb-4">
        Now, here's a nifty feature: we've included a search bar right above the data table. Use it to quickly search
        for specific information within the extracted table. No more manual scanning or tedious data entry!
    </p>

    <p className="mb-4">
        Have a lengthy PDF with multiple pages? Not a problem! Our tool will paginate the pages for you. Simply click
        on the "Next" button to navigate to the next page, where we'll extract any data present and display it in data
        tables.
    </p>

    <p className="mb-4">
        Please note that while viewing the tables, you'll see data only from the current page. We've designed it this
        way for easy navigation and focused analysis.
    </p>

    <p className="mb-4">
        Lastly, if you wish to download the extracted data for your records or further analysis, we've got you covered.
        Look for the download buttons located above the data table. You'll have the option to download your data in
        either Excel or CSV format – whichever suits your needs best.
    </p>`
    },
    {
        id: "3",
        date: "15 Oct, 2023",
        title: "How We Secure Your Data",
        desc: `At Bank Statement Converter AI, safeguarding your data and information is our top priority. Here, we'll take you
        through the steps we take to ensure the security of your valuable data.`,
        img: image3,
        content: `<p className="mb-4">
        At Bank Statement Converter AI, safeguarding your data and information is our top priority. Here, we'll take you
        through the steps we take to ensure the security of your valuable data.
    </p>

    <p className="mb-4">
        <strong>Your Account: Your First Line of Defense</strong>
    </p>

    <p className="mb-4">
        First and foremost, we require you to have an account to use Bank Statement Converter AI. This not only grants
        you access but also provides several security benefits. When we store your documents for processing, they are
        encrypted with your account details. This ensures that your sensitive information remains confidential and
        protected.
    </p>

    <p className="mb-4">
        <strong>Encryption in Transit and at Rest</strong>
    </p>

    <p className="mb-4">
        We go the extra mile to secure your data both in transit and at rest. When we say "in transit," we mean that
        your data is protected as it travels between your device and our servers. "At rest" means that when your data
        is stored on our servers, it remains encrypted, adding an extra layer of security.
    </p>

    <p className="mb-4">
        <strong>Data Retention Policy</strong>
    </p>

    <p className="mb-4">
        To further prioritize your privacy, we automatically delete any data you upload or we generate from analyzing
        your document within 24 hours. This ensures that your data is not stored longer than necessary.
    </p>

    <p className="mb-4">
        <strong>The Power of Encryption</strong>
    </p>

    <p className="mb-4">
        Because your documents are encrypted, even if someone somehow gains access to a link to your document, they
        won't be able to access it without your account information. Your data remains locked and secure.
    </p>

    <p className="mb-4">
        <strong>URL Signatures for Added Security</strong>
    </p>

    <p className="mb-4">
        Every URL generated to access your document must be signed, adding an extra layer of security. To calculate a
        signature, we follow a stringent process. We concatenate select request elements to form a string known as the
        "string to sign." Then, we use a signing key to calculate the hash-based message authentication code (HMAC) of
        the string to sign. Our storage service validates the signature, ensuring that only authorized requests are
        processed.
    </p>

    <p className="mb-4">
        If you have any questions or want to learn more about how we secure your data, please don't hesitate to contact
        us at <a href="mailto:iMasterSpanish@gmail.com">iMasterSpanish@gmail.com</a>. Your
        security is our commitment.
    </p>`
    },
    {
        id: "4",
        date: "17 Oct, 2023",
        title: "5 Essential Reasons Why You Need Bank Statement Converter AI!",
        desc: `In the era of digital banking and online financial transactions, keeping track of one's finances is more crucial than ever.`,
        img: image4,
        content: ` <p className="mb-4">In the era of digital banking and online financial transactions, keeping track of one's finances is more crucial than ever. Although banks provide monthly statements, they often come in formats that aren't easy to manipulate, analyze, or integrate with other software. This is where the importance of a bank statement converter to Excel or CSV comes into play. But why exactly do you need one? Let’s delve into five compelling reasons.</p>

        <p className="mb-4">1. <strong>Seamless Record Keeping:</strong> The primary purpose of converting bank statements is to maintain a consistent and accessible record. By converting your statements into Excel or CSV formats, you can effortlessly store, retrieve, and back up your financial data, ensuring you have access to it whenever you need.</p>

        <p className="mb-4">2. <strong>Data Aggregation:</strong> Using a converter allows you to aggregate all your bank data in one place. This is particularly useful if you have multiple bank accounts. By consolidating all statements into one format, you gain a holistic view of your finances, making it easier to manage and monitor them.</p>

        <p className="mb-4">3. <strong>Enhanced Data Analysis:</strong> Once you've converted your statements to Excel or CSV, you can utilize various analytical tools to understand your spending habits, track monthly expenses, and forecast future financial scenarios. Such insights can be instrumental in creating budgets and making informed financial decisions.</p>

        <p className="mb-4">4. <strong>Integration with Financial Software:</strong> Many financial management and budgeting software require data in Excel or CSV format for smooth integration. By converting your bank statements, you ensure that you can sync your data with tools like QuickBooks, Microsoft Money, or any other financial planning software seamlessly.</p>

        <p className="mb-4">5. <strong>Preparation for Financial Consultations:</strong> If you're planning to see a financial advisor or consultant, having your bank statements in a manipulatable format can be extremely beneficial. It allows for easy sharing, quicker analysis, and can streamline the advisory process, making your consultations more productive.</p>

        <p className="mb-4">In conclusion, a bank statement converter is not just a tool—it's an essential component for modern financial management. Whether you're a professional, a student, or someone trying to get a grip on their finances, a converter is the bridge between cluttered data and financial clarity. So, if you haven’t already, it’s time to invest in a reliable bank statement converter and take control of your financial future.</p>
        `
    }
];
