import { createContext } from "react";

interface contextProps {
    userId: [
        userId: string,
        setUserId: (e: string) => void
    ];
    userDisplayName: [
        userDisplayName: string,
        setUserDisplayName: (e: string) => void
    ];
    isAuthAttempted: [
        isAuthAttempted: boolean | false,
        setIsAuthAttempted: (e: boolean) => void
    ];
    isAuthSuccess: [
        isAuthSuccess: boolean | false,
        setIsAuthSuccess: (e: boolean) => void
    ];
    rewardPoints: [
        rewardPoints: number,
        setRewardPoints: (e: number) => void
    ];
    isSubscribed: [
        isSubscribed: boolean,
        setIsSubscribed: (e: boolean) => void
    ];
}

const AppContext = createContext<contextProps | null>(null);

export default AppContext;
