import React, { Attributes, useContext, useEffect, useState } from 'react'
import { Col, Container, Row, Spinner } from 'reactstrap';


//Import Bloglist Data
import { useNavigate, useParams } from 'react-router-dom';
import { LessonSubtopic } from '../Course/courseData';
import { getUrlTitle } from 'src/utils/Utils';
import { useGetLesson, useGetLessonCards } from './Hooks/lessonHooks';
import AppContext from 'src/components/Hooks/createContext';
import { verifyUsage } from 'src/helpers/usage/usage_helper';
import AppModal from 'src/components/CommonForBoth/Modal/AppModal';
import Paywall from '../Subscriptions/Paywall';
import AuthenticatedWrapper from '../Account/account';
import { Amplify } from 'aws-amplify';
import RenderSubtopic from './RenderSubTopic';

interface ImageLinks {
    [id: string]: string;
}

const Lesson = (props: any) => {

    const {
        isSubscribed: [isSubscribed, setIsSubscribed],
    } = useContext(AppContext)!;

    let navigate = useNavigate();

    let { lessonId } = useParams<{ lessonId: string }>();
    lessonId = lessonId || props.lessonId;

    const lessonCards = useGetLessonCards(completeLoading);

    const lesson = useGetLesson(lessonCards, lessonId);

    const [showPaymentModal, setShowPaymentModal] = React.useState(false);
    const [showAuthForm, setShowAuthForm] = React.useState(false);

    const [isLoading, setIsLoading] = useState(true);

    function completeLoading(data?: any) {
        setIsLoading(false);
    }

    // State to store image links
    const [imageLinks, setImageLinks] = useState<ImageLinks>({});

    // Function to add or update an image link
    const addImageLink = (id: string, link: string): void => {
        setImageLinks(prev => ({
            ...prev,
            [id]: link
        }));
    };

    // Function to get an image link by ID
    const getImageLink = (id: string): string | undefined => {
        return imageLinks[id];
    };


    // Function to change the active subtopic
    const changeSubtopic = (subtopic: LessonSubtopic) => {
        if (!lesson) return;
        if (!subtopic) return;
        if (subtopic.isPaid && !isSubscribed) {
            // check if user is logged in
            Amplify.Auth.currentAuthenticatedUser()
                .then((_: Attributes) => {
                    verifyUsage()
                        .then((isSubscribed: boolean) => {
                            setIsSubscribed(isSubscribed);
                            if (!isSubscribed) {
                                setShowPaymentModal(true);
                            }
                        });
                })
                .catch((error: any) => {
                    console.log("error", error);
                    setShowAuthForm(true);
                });
            return;
        }
        navigate(`/lesson/${lessonId}/${getUrlTitle(lesson.details.title)}/${subtopic.id}/${getUrlTitle(subtopic.title)}`);
    };

    // Function to navigate back to the lessons page
    const goBackToLessons = () => {
        navigate('/lessons'); // Assuming '/lessons' is the path to your lessons page
    };


    useEffect(() => {
        // Verify usage
        if (!isSubscribed) {
            verifyUsage()
                .then((isSubscribed: boolean) => {
                    setIsSubscribed(isSubscribed);
                });
        }
    }, []);

    const onModalToggle = () => {
        setShowAuthForm(false);
        setShowPaymentModal(false);
    }

    function RenderSubtopics() {
        if (!lesson) return null;

        const lessonDetails = {
            "details": {
                id: lesson.details.id,
            }
        };

        let subtopicsElements = [];
        let i = 0
        while (i < lesson.subtopics.length - 1) {
            let leftSubtopic = lesson.subtopics[i];
            let rightSubtopic = lesson.subtopics[i + 1];

            // Check if the subtopic is completed

            subtopicsElements.push(
                <div key={`subtopics-timeline-${i}`} className="timeline-continue">
                    <Row>
                        <Col md={6} className='timeline-left'>
                            <RenderSubtopic
                                subtopic={leftSubtopic}
                                changeSubtopic={changeSubtopic}
                                lesson={lessonDetails} />
                        </Col>
                        <Col md={6} className='timeline-right'>
                            <RenderSubtopic
                                subtopic={rightSubtopic}
                                changeSubtopic={changeSubtopic}
                                lesson={lessonDetails} />
                        </Col>
                    </Row>
                </div>
            );
            i += 2;
        }
        return subtopicsElements;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col className="text-left mb-2">
                            <button onClick={goBackToLessons} className="btn btn-primary">
                                <i className={`bx bx-left-arrow-alt`}></i> Back to Lessons
                            </button>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xl={12}>

                            <div className="timeline">
                                {/* Timeline Start */}
                                <div className="timeline-container">
                                    <div className="timeline-start">
                                        <p>Start 🇪🇸🇲🇽</p>
                                    </div>
                                    {/* Subtopics List Section */}
                                    {!isLoading && RenderSubtopics()}
                                    {isLoading &&
                                        <div className="text-center">Loading...
                                            <Spinner color="primary" />
                                        </div>
                                    }
                                </div>
                                {/* Timeline End */}
                                <div className="timeline-end">
                                    <p>End</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {showPaymentModal &&
                            <AppModal
                                show={true}
                                title={"This lesson is availabe to subscribers only. Please subscribe to unlock all lessons."}
                                onModelToggle={onModalToggle}>
                                {!isSubscribed &&
                                    <Paywall pricingTableOnly={true} />
                                }
                            </AppModal>
                        }
                        {
                            showAuthForm &&
                            <AuthenticatedWrapper
                                title="Please login or create an account to continue."
                                onModelToggle={onModalToggle} />
                        }
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );


}

export default Lesson
