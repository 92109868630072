import { Authenticator } from '@aws-amplify/ui-react';
import { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const AppModal = (props: any) => {
    const [modal, setModal] = useState(true);

    const toggle = () => {
        props.onModelToggle(!modal);
        setModal(!modal);
    }

    return (
        <>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} className="justify-content-center">
                    <div className="text-center w-100">
                        {props.title}
                    </div>
                </ModalHeader>
                <ModalBody>
                    {props.children}
                </ModalBody>
            </Modal>
        </>
    );
};

export default AppModal;
