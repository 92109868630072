import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback, useState } from "react";

//Import Icons
import FeatherIcon from "feather-icons-react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

//Import images
import giftBox from "../../assets/images/giftbox.png";

//i18n
import { withTranslation } from "react-i18next";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, useLocation } from "react-router-dom";

import withRouter from "../../../src/components/Common/withRouter";
import { Auth, I18n } from "aws-amplify";
import { checkUserAuthentication } from "src/utils/Utils";
import { verifyUsage } from "src/helpers/usage/usage_helper";

const SidebarContent = (props: any) => {
  const ref: any = useRef();
  const [email, setEmail] = useState<string>("");

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(true);

  useEffect(() => {
    verifyUsage().then((_) => {
      setIsSubscribed(true);
    }).catch((error: any) => {
      setIsSubscribed(false);
      console.error(error);
    });
  }, []);

  useEffect(() => {
    checkUserAuthentication().then((isAuthentcated) => {
      setIsAuthenticated(isAuthentcated);
    });
  }, [])

  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items: any) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul: any = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  useEffect(() => {
    Promise.resolve(Auth.currentUserInfo()).then((userInfo: any) => {
      if (userInfo && userInfo.attributes) {
        setEmail(userInfo.attributes.email);
      }
    });
  }, []);

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }



  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              {isAuthenticated &&
                <Link to="/home" className="">
                  <FeatherIcon icon="home" /> <span>{I18n.get("Home")}</span>
                </Link>
              }
              {!isAuthenticated &&
                <Link to="/" className="">
                  <FeatherIcon icon="home" /> <span>{I18n.get("Home")}</span>
                </Link>
              }
            </li>
          </ul>
          {/* <li>
              {!isAuthenticated &&
                <Link to="/demo" className="">
                  <FeatherIcon icon="navigation" /> <span className="bg-success-subtle text-success">{I18n.get("Test Drive!")}</span>
                </Link>
              }
            </li>
            <li>
              {isAuthenticated && !isSubscribed &&
                <Link to="/pricing">
                  <FeatherIcon icon="dollar-sign" /> <span>{props.t("Pricing")}</span>
                </Link>
              }
              {!isAuthenticated &&
                <Link to="/pricing-non-auth">
                  <FeatherIcon icon="dollar-sign" /> <span>{props.t("Pricing")}</span>
                </Link>
              }
            </li>
            <li>
              <Link to={`https://billing.stripe.com/p/login/bIYeWT9164O50U0dQQ?prefilled_email=${email}`}
                className="" target="_blank">
                <FeatherIcon icon="credit-card" /> <span>{props.t("Manage My Subscription")}</span>
              </Link>
            </li>
            <li>
              <Link to="/blog">
                <FeatherIcon icon="pen-tool" /> <span>{props.t("Blog")}</span>
              </Link>
            </li>
            <li className="menu-title">{props.t("Help")} </li>
            <li>
              <Link to="/faq">
                <FeatherIcon icon="help-circle" /> <span>{props.t("FAQs")}</span>
              </Link>
            </li>
            <li className="menu-title">{props.t("Legal")} </li>
            <li>
              <Link to="/privacy-policy">
                <FeatherIcon icon="lock" /> <span>{props.t("Privacy Policy")}</span>
              </Link>
            </li>
            <li>
              <Link to="/tos">
                <FeatherIcon icon="clipboard" /> <span>{props.t("Terms & Conditions")}</span>
              </Link>
            </li>
          </ul>
          <div className="card sidebar-alert border-0 text-center mx-4 mb-0 mt-5">
            <div className="card-body">
              <img src={giftBox} alt="" />
              <div className="mt-4">
                <h5 className="alertcard-title font-size-16">
                  Need to analyze more pages?
                </h5>
                <p className="font-size-13">
                  Check out our plans and select the one that fits your need.
                </p>
                <a href="/pricing" className="btn btn-primary mt-2">
                  Upgrade Now!
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(SidebarContent));
