/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "api0615e402",
            "endpoint": "https://0j4x2wtem3.execute-api.us-east-1.amazonaws.com/mainline",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:f75aaae8-ad65-42ef-ad74-16529927844d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_DqGrP3YTk",
    "aws_user_pools_web_client_id": "1jelpklibhh7h6r90pgibpb768",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "imasterspanis64807-mainline",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "imasterspanishapp-mainline",
            "region": "us-east-1"
        }
    ],
    "predictions": {
        "convert": {
            "speechGenerator": {
                "region": "us-east-1",
                "proxy": false,
                "defaults": {
                    "VoiceId": "Mia",
                    "LanguageCode": "es-MX"
                }
            }
        }
    }
};


export default awsmobile;
