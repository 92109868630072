import React, { createContext, useEffect, useState } from "react";
import { authRoutes, nonAuthRoutes } from "./routes/allRoutes";
import { Route, Routes } from "react-router-dom";
import VerticalLayout from "./components/VerticalLayout";
import HorizontalLayout from "./components/HorizontalLayout";
import { useSelector } from "react-redux";
import { Auth, Hub } from "aws-amplify";
import '@aws-amplify/ui-react/styles.css';
// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";


function hideSplashScreen() {
  let splashScreen = document.getElementById("splash-screen");
  if (splashScreen) {
    splashScreen.style.display = 'none';
  }
}

const App = (props: any) => {
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(_ => {
        hideSplashScreen();
      })
      .catch(() => {
        hideSplashScreen();
      });
  }, []);



  const { layoutType } = useSelector((state: any) => ({
    layoutType: state.Layout.layoutType,
  }));

  function getLayout() {
    let layoutCls: Object = VerticalLayout;
    switch (layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout: any = getLayout();
  return (
    <React.Fragment>
      <Routes>
        {nonAuthRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Layout>{route.component}</Layout>
            }
            key={idx}
          />
        ))}

        {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Layout>{route.component}</Layout>
            }
            key={idx}
          />
        ))}
      </Routes>
    </React.Fragment >
  );
};

export default App;


