import { useContext, useEffect, useState } from "react";
import { SubLessonContent } from "../../Course/lessonData";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    ListGroup,
    ListGroupItem,
} from 'reactstrap';
import PracticeSection from "./PracticeSection";
import { ButtonOptions, DictateTextToSpanish } from "./DictateTextToSpanish";
import { Language, convertTextToSpeech } from "src/helpers/aws/aws_amplify_predictions";
import { addRewardPoints, getRewardPoints, isSubLessonCompleted, markSubLessonAsCompleted } from "src/helpers/aws/aws_user_data";
import { getSubLessonContent } from "src/helpers/aws/aws_s3_helper";
import { useNavigate } from "react-router";
import App from "src/App";
import AppContext from "src/components/Hooks/createContext";
import { get, set } from "lodash";

export interface LessonProps {
    lessonId: string;
    subLessonId: string;
}



export interface VocabularyItemProps {
    term: string;
    definition: string;
}

const VocabularyItem: React.FC<VocabularyItemProps> = ({ term, definition }) => (
    <ListGroupItem className="vocabulary-item">
        <Row>
            <Col xs={6} md={6} className="vocabulary-term">
                <h5><strong>{term}</strong></h5>
            </Col>
            <Col xs={6} md={6} className="vocabulary-definition">
                <h5>{definition}</h5>
            </Col>
        </Row>
        <DictateTextToSpanish
            text={term}
            buttonOption={ButtonOptions.All} />
    </ListGroupItem>
);


const Lesson: React.FC<LessonProps> = ({ lessonId, subLessonId }) => {

    const {
        rewardPoints: [, setRewardPoints],
    } = useContext(AppContext)!;

    const [subLessonRemoteData, setSubLessonRemoteData] = useState<SubLessonContent>();

    const [selectedAnswers, setSelectedAnswers] = useState<Record<string, string>>({});
    const [validation, setValidation] = useState<Record<string, boolean>>({});
    const [points, setPoints] = useState(0);

    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const [isCompleted, setIsCompleted] = useState<boolean>(false);

    useEffect(() => {
        // isSubLessonCompleted(lessonId, subLessonId)
        //     .then((isComplete: boolean) => {
        //         setIsCompleted(isComplete);
        //     })
    }, [])

    // back to lessons
    const goBackToLessons = () => {
        navigate(`/lessons/${lessonId}/${subLessonRemoteData?.content.title}`); // Assuming '/lessons' is the path to your lessons page
        // navigate('/lessons'); // Assuming '/lessons' is the path to your lessons page
    };

    useEffect(() => {
        setIsLoading(true);

        getSubLessonContent(lessonId, subLessonId)
            .then((response: SubLessonContent | null) => {
                if (!response) return;
                setSubLessonRemoteData(response);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [lessonId, subLessonId]);

    const markCompleted = () => {
        addRewardPoints(points);
        markSubLessonAsCompleted(lessonId, subLessonId);
    }

    const handleRadioChange = (questionId: string, selectedOption: string,
        correctAnswer: string | Set<string>, selectedOptionExplanation: string) => {
        const isCorrect = (selectedOption === correctAnswer ||
            (correctAnswer instanceof Array && correctAnswer.includes(selectedOption)) ||
            (correctAnswer instanceof Set && correctAnswer.has(selectedOption)));
        setSelectedAnswers({
            ...selectedAnswers,
            [questionId]: selectedOption,
        });
        setValidation({
            ...validation,
            [questionId]: isCorrect,
        });

        // Update points, don't update if lesson is already completed
        // !validation[questionId] checks if the answer is not correct or not answered
        if (!isCompleted && isCorrect && !validation[questionId]) {
            // This checks if the answer was not previously correct to prevent adding points on re-selecting the correct answer
            setPoints(prevPoints => prevPoints + 1);
            addRewardPoints(10);
            setRewardPoints(getRewardPoints());
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!subLessonRemoteData) {
        return <div>Lesson in progress...</div>;
    }

    const { title, description, instructions, vocabulary, practice } = subLessonRemoteData.content;

    return (
        <Container>
            <Row className="justify-content-center">
                <Col lg="12">
                    <Card className="my-3">
                        <CardBody>
                            <CardTitle tag="h1">{title}</CardTitle>
                            <p>{description}</p>
                            {/* Show check mark and show completed */}
                            {isCompleted &&
                                <div className="text-center text-success">
                                    <span className="checkmark">&#x2713;</span> {/* Unicode for checkmark */}
                                    <span className="completed-text font-size-20">Completed!</span>
                                </div>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg="12">
                    <Card className="mb-3">
                        <CardBody>
                            <CardTitle tag="h2">Vocabulary</CardTitle>
                            <p>{instructions.content}</p>
                            <ListGroup>
                                {vocabulary.map((item, index) => (
                                    <VocabularyItem key={index} term={item.term} definition={item.definition} />
                                ))}
                            </ListGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg="12">
                    <Card className="mb-3">
                        <CardBody>
                            <CardTitle tag="h2">Examples</CardTitle>
                            <p>
                                Practice pronounciation by playing the audio in slow, normal, or fast mode.
                                Listen carefully to the pronounciation and try it yourself.
                            </p>
                            <ListGroup>
                                {instructions.examples.map((example, index) => (
                                    <ListGroupItem key={index}>
                                        <strong>Example:</strong> {example.text} <em>({example.translation})</em>
                                        <Col>
                                            <DictateTextToSpanish
                                                key={index}
                                                text={example.text}
                                                buttonOption={ButtonOptions.All} />

                                        </Col>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {/* Practice Section */}
            <PracticeSection
                goBackToLessons={goBackToLessons}
                practice={practice}
                selectedAnswers={selectedAnswers}
                validation={validation}
                handleRadioChange={handleRadioChange}
                markCompleted={markCompleted}
            />
        </Container >
    );
};

export default Lesson;
