import { combineReducers } from "redux"

// Layout
import Layout from "./layout/reducer";

// Calendar
import Calendar from "./calendar/reducer";

//Chat
import Chat from "./chat/reducer";

//Invoices
import Invoices from "./invoices/reducer";

//Contact
import contacts from "./contacts/reducer";

//Dashboard
import dashboard from "./Dashboard/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Calendar,
  Chat,
  Invoices,
  contacts,
  dashboard
})

export default rootReducer
