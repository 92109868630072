import { useState } from "react";
import AppContext from "./createContext";

const AppContextProvider = (props: {
    children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}) => {
    const [isAuthAttempted, setIsAuthAttempted] = useState<boolean | false>(true);
    const [isAuthSuccess, setIsAuthSuccess] = useState<boolean | false>(false);

    const [userId, setUserId] = useState<string>("");
    const [userDisplayName, setUserDisplayName] = useState<string>("");

    const [rewardPoints, setRewardPoints] = useState<number>(0);

    const [isSubscribed, setIsSubscribed] = useState<boolean>(false);

    return (
        <AppContext.Provider
            value={{
                isAuthAttempted: [isAuthAttempted, setIsAuthAttempted],
                isAuthSuccess: [isAuthSuccess, setIsAuthSuccess],
                userId: [userId, setUserId],
                userDisplayName: [userDisplayName, setUserDisplayName],
                rewardPoints: [rewardPoints, setRewardPoints],
                isSubscribed: [isSubscribed, setIsSubscribed],
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
