export const esAppDict = {
    'Menu': 'Menu',
    'designed_by': 'Designed & Developed by',
    'Logout': 'Logout',
    'paywall_lead': 'Unlock the Full Potential of iMasterSpanish! Subscribe now and enjoy effortless PDF and image conversion to Excel or CSV. Say goodbye to tedious data entry and hello to more time for what matters most!',
    'loading': 'Loading...',
    'delete': 'Delete',
    'yes': 'Yes',
    'no': 'No',
    'cancel': 'Cancel',
    'thank_you': 'Thank you',
    'for_subs': 'for Subscribing!',
    'or': "Or",
    "thank_you_start_converting_your_documents": "You have unlocked the Full Potential of iMasterSpanish! Effortless converter your PDFs and images to Excel or CSV. Say goodbye to tedious data entry!"
};
