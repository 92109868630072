import { Cache, Storage } from "aws-amplify";
import { LessonDetails } from "src/pages/Course/courseData";
import { SubLessonContent } from "src/pages/Course/lessonData";

const LESSONS_CARD_FOLDER = "lessonCards";
const LESSONS_FOLDER = "lessons";
const COMPLETED_LESSONS_FOLDER = "completedLessons";
const SUB_LESSONS_FOLDER = "sublessons";

export enum AccessLevel {
    Public = "public",
    Protected = "protected",
    Private = "private",
}

async function processDetails(details: LessonDetails[]) {

    const lessonDetails = [];

    for (const mainTopic of details) {
        let importedImage = await import(`../../assets/images/lessons/${mainTopic.details.id}/${mainTopic.details.id}.jpg`);

        const subtopicsPromises = mainTopic.subtopics.map(async subtopic => {
            try {
                let importedSubtopicImage = await import(`../../assets/images/lessons/${mainTopic.details.id}/subtopics/${subtopic.id}.jpg`);
                // const isCompleted = await isSubLessonInLessonCompleted(mainTopic.details.id, subtopic.id);
                // subtopic.isCompleted = isCompleted;
                return {
                    ...subtopic,
                    photoUrl: importedSubtopicImage.default
                };
            } catch (error) {
                console.error(error);
                return subtopic;
            }
        });

        const subtopics = await Promise.all(subtopicsPromises);

        lessonDetails.push({
            details: {
                ...mainTopic.details,
                photoUrl: importedImage.default,
            },
            subtopics
        });
    }

    return lessonDetails;
}

// Get lesson cards store in lessonCards folder/cards.json using await Storage.get
export const getLessonCards = async (): Promise<LessonDetails[] | null> => {
    try {
        const cachedLessonCards = await Cache.getItem('lessonCards');
        if (cachedLessonCards) {
            return cachedLessonCards;
        }

        const response: any = await Storage.get(`${LESSONS_CARD_FOLDER}/cards.json`, {
            level: AccessLevel.Public,
            download: true,
        });

        if (!response) {
            console.error('No response from Storage.get');
            return null;
        }
        if (!response.Body) {
            console.error('Response.Body is empty');
            return null;
        }

        // data.Body is a Blob
        const responseText = await response.Body?.text();
        if (!responseText) {
            console.error('Response.Body.text() is empty');
            return null;
        }

        let details: LessonDetails[] = await processDetails(JSON.parse(responseText));

        Cache.setItem('lessonCards', details, {
            expires: 30 * 60 * 1000,  // 30 minutes expiration
        });
        return details;
    } catch (error) {
        console.error('Error fetching lesson cards:', error);
        return null;
    }
}

export const getSubLessonContent = (lessonId: string, subLessonId: string): Promise<SubLessonContent | null> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response: any = await Storage.get(`${LESSONS_FOLDER}/${lessonId}/${SUB_LESSONS_FOLDER}/${subLessonId}.json`, {
                level: AccessLevel.Public,
                download: true,
            });

            if (!response) {
                console.error('No response from Storage.get');
                return null;
            }

            // data.Body is a Blob
            if (!response.Body) {
                console.error('Response.Body is empty');
                return null;
            }
            const responseText = await response.Body?.text();
            if (!responseText) {
                console.error('Response.Body.text() is empty');
                return null;
            }

            const subtopic: SubLessonContent = JSON.parse(responseText);
            console.log('subtopic', subtopic);
            resolve(subtopic);
        } catch (error) {
            console.error('Error fetching sub lesson:', error);
            reject(error);
        }
    });
}

// Add completed sublesson for user in s3 using Amplify Storage.put
async function addCompletedSubLessons(lessonId: string, subLessonId: string) {
    if (lessonId === "" || subLessonId === "" || lessonId === undefined || subLessonId === undefined) {
        return;
    }
    // add completed sub lesson by id for lessonId to s3
    // we will use this to track user progress and reward points
    try {
        await Storage.put(`${COMPLETED_LESSONS_FOLDER}/${lessonId}/${subLessonId}/info.json`, {
            contentType: 'application/json',
            body: JSON.stringify({ completed: true }),
        }, {
            level: AccessLevel.Private,
        });
    } catch (error) {
        console.error('Error adding completed sub lesson:', error);
    }
}



// Record user progress data in s3
// We will use this to track user progress and reward points
export const recordUserProgress = async (lessonId: string, subLessonId: string) => {
    // add completed sub lesson by id for lessonId to cache
    await addCompletedSubLessons(lessonId, subLessonId);
}

export const getCompletedSubLessons = async (lessonId: string): Promise<Set<string>> => {
    // Read completed sub lessons from s3
    // We will read all subfolders in completedLessons/lessonId
    // and return a Set of sublesson ids
    try {

        const response = await Storage.list(`${COMPLETED_LESSONS_FOLDER}/${lessonId}`, {
            level: AccessLevel.Private,
        });

        if (!response) {
            console.error('No response from Storage.list');
            return new Set();
        }
        const subLessons = response.results.map(item => item && item.key ? item.key.split('/')[2] : "");
        return new Set(subLessons);
    } catch (error) {
        console.error('Error fetching completed sub lessons:', error);
        return new Set();
    }
}

// Count completed sub lessons for a lesson
export const countCompletedSubLessons = async (lessonId: string): Promise<number> => {
    const completedSubLessons = await getCompletedSubLessons(lessonId);
    return completedSubLessons.size;
}

// Check if a given sub lesson is completed
export const isSubLessonInLessonCompleted = async (lessonId: string, subLessonId: string): Promise<boolean> => {
    const completedSubLessons = await getCompletedSubLessons(lessonId);
    return completedSubLessons.has(subLessonId);
}

