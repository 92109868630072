import { Badge, Card, CardBody, Col, Progress, Row, Spinner } from "reactstrap";
import { LessonData } from "./courseData";
import { useNavigate } from "react-router";
import { getUrlTitle } from "src/utils/Utils";
import React, { Attributes, useContext, useEffect } from "react";
import { completedSubLessonCount } from "src/helpers/aws/aws_user_data";
import { Link } from "react-router-dom";
import AppModal from "src/components/CommonForBoth/Modal/AppModal";
import Paywall from "../Subscriptions/Paywall";
import AppContext from "src/components/Hooks/createContext";
import AuthenticatedWrapper from "../Account/account";
import { verifyUsage } from "src/helpers/usage/usage_helper";
import { Amplify } from "aws-amplify";
import { set } from "lodash";

export const LessonCard: React.FC<{ lesson: LessonData }> = ({ lesson }) => {

    const {
        isSubscribed: [isSubscribed, setIsSubscribed],
    } = useContext(AppContext)!;


    const [cardLoading, setCardLoading] = React.useState(true);

    const [showPaymentModal, setShowPaymentModal] = React.useState(false);

    const [showAuthForm, setShowAuthForm] = React.useState(false);

    const navigate = useNavigate();

    // progress, rounded to the nearest integer

    const [roundedProgress, setRoundedProgress] = React.useState<number>(0);

    const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

    const isComingSoon = lesson.isComingSoon;

    useEffect(() => {
        const subLessonCount = lesson.subtopicCount;
        completedSubLessonCount(lesson.id)
            .then((completedSublessons: number) => {
                const progress = (completedSublessons / subLessonCount) * 100;
                setRoundedProgress(Math.round(progress));
                setIsCompleted(subLessonCount === completedSublessons);
                setCardLoading(false);
            });
    }, []);



    const onModalToggle = () => {
        setShowPaymentModal(false);
        setShowAuthForm(false);
    }

    let commingSoonInfo = isSubscribed ?
        'Thank you for your support. We are working hard to bring you this lesson soon.' :
        `This lesson is comming soon. To access this lesson when available, please subscribe.`;

    const _LessonCard = () => <Card className="lessonCard">
        <img
            className="card-img img-fluid"
            src={lesson.photoUrl}
            alt=""
            style={{
                height: "54vh", // Set a fixed height
                objectFit: 'cover', // Prevent stretching
            }} />
        {cardLoading &&
            <div className="card-img-overlay d-flex justify-content-center align-items-center">
                <Spinner color="tertiary" />
            </div>}
        <div
            className="card-img-overlay"
            style={{
                backgroundColor: 'rgba(0, 0, 0, 0.60)', // Increase the opacity for better text readability
                padding: '1rem', // Add padding around the overlay content
            }}
        >
            <CardBody>
                <Row className="mb-4">
                    <Row>
                        <Col className="d-flex justify-content-between">
                            <Badge color={lesson.isComingSoon ? "info" : "success"} pill className="font-size-14 align-self-start">
                                {lesson.level}
                            </Badge>
                            {isCompleted &&
                                <i className="bx bx-check-circle h3 mb-0 me-4 text-success font-size-22 align-self-end">
                                </i>}
                        </Col>
                    </Row>
                    <h5 className="mt-4 card-title text-white font-size-24">{lesson.title}</h5>
                    <h6 className="card-subtitle text-white font-size-16">{lesson.description}</h6>
                </Row>
                <Row>
                    <Col className="d-flex">
                        <i className="bx bx-time h4 font-size-18 text-white"></i>
                        <span className="text-white ms-2 font-size-16">{lesson.durationInMinutes} minutes</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex">

                        {isCompleted && <i className="bx bx-check h4 font-size-18 text-white"></i>}
                        {roundedProgress === 0 && !isCompleted && <i className="bx bx-sync h4 font-size-18 text-white"></i>}
                        {roundedProgress > 0 && !isCompleted && <i className="bx bx-loader-circle  h4 font-size-18 text-white"></i>}

                        <span className="text-white ms-2 font-size-16">
                            {isCompleted
                                ? "Completed"
                                : roundedProgress > 0
                                    ? "In Progress"
                                    : "Not Started"}
                        </span>
                    </Col>
                </Row>
                {!isComingSoon && <Row>
                    <Col className="d-flex">
                        <span className="h4 font-size-16 text-white">{roundedProgress}%</span>
                        <span className="text-white ms-2">Progress</span>
                    </Col>
                    <div className="animated-progess mb-4">
                        <Progress value={roundedProgress} color="primary"></Progress>
                    </div>
                </Row>
                }
                {!isComingSoon && <Row>
                    <Col className="d-flex justify-content-end mt-3">
                        <button
                            type="button"
                            onClick={() => {
                                navigate(`/lesson/${lesson.id}/${getUrlTitle(lesson.title)}`);
                            }}
                            className="btn btn-primary d-flex align-items-center justify-content-center font-size-16 position-relative"
                        >
                            {roundedProgress === 100
                                ? "Completed"
                                : roundedProgress > 0.0
                                    ? "Continue"
                                    : "Start"}
                            <span className="d-flex align-items-center ms-2">
                                <i className="bx bx-right-arrow-alt font-size-18"></i>
                            </span>
                        </button>
                    </Col>
                </Row>
                }
            </CardBody>
        </div>
    </Card>;
    return (
        <>
            {!isComingSoon &&
                < Link to={`/lesson/${lesson.id}/${getUrlTitle(lesson.title)}`}>
                    {_LessonCard()}
                </Link >
            }
            {isComingSoon &&
                <Link to="" onClick={() => {
                    Amplify.Auth.currentAuthenticatedUser()
                        .then((_: Attributes) => {
                            if (isSubscribed) {
                                commingSoonInfo = 'Thank you for your support. We are working hard to bring you this lesson soon.';
                            }
                            setShowPaymentModal(true);
                        })
                        .catch((error: any) => {
                            console.log("error", error);
                            setShowAuthForm(true);
                        });
                }}>
                    {showPaymentModal &&
                        <AppModal
                            show={true}
                            title={commingSoonInfo}
                            onModelToggle={onModalToggle}>
                            {!isSubscribed &&
                                <Paywall pricingTableOnly={true} />
                            }
                        </AppModal>
                    }
                    {
                        showAuthForm &&
                        <AuthenticatedWrapper
                            title="Please login or create an account to get notified when the lesson is available."
                            onModelToggle={onModalToggle} />
                    }
                    {_LessonCard()}
                </Link>
            }
        </>
    );
}
