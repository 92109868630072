import React, { useState, useEffect, useContext } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { Auth, I18n } from "aws-amplify";
import AppContext from "src/components/Hooks/createContext";
import { set } from "lodash";

const ProfileMenu = () => {
  const {
    isAuthSuccess: [isAuthSuccess, _],
    userId: [userId, setUserId],
    userDisplayName: [userDisplayName, setUserDisplayName],
  } = useContext(AppContext)!;

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  const getUserDisplayName = () => Promise.resolve(Auth.currentUserInfo())
    .then((userInfo: any) => {
      if (userInfo && userInfo.attributes) {
        setUserId(userInfo.attributes.sub);
        setUserDisplayName(userInfo.attributes.name);
      }
    });

  useEffect(() => {
    getUserDisplayName()
  }, []);

  useEffect(() => {
    getUserDisplayName()
  }, [isAuthSuccess]);


  return (
    <React.Fragment>
      {userId && <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="">{userDisplayName || `Account`}</span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <a className="dropdown-item hover-pointer" onClick={async () => {
            setUserId("");
            setUserDisplayName("");
            Auth.signOut();
          }}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{I18n.get("Logout")}</span>
          </a>
        </DropdownMenu>
      </Dropdown>
      }
    </React.Fragment>
  );
};

export default withTranslation()(ProfileMenu);
