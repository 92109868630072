import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";
import ThankYou from "src/pages/Subscriptions/thank_you";
import Paywall from "src/pages/Subscriptions/Paywall";
import PagePricing from "src/pages/Utility/PagePricing";
import PageFaqs from "src/pages/Utility/PageFAQs";
import TOS from "src/pages/Legal/TOS";
import PrivacyPolicy from "src/pages/Legal/PrivacyPolicy";
import ArticleDetails from "src/pages/Blog/articleDetails";
import Blog from "src/pages/Blog";
import Lesson from "src/pages/Lesson";
import LessonDetails from "src/pages/Lesson/Details";
import AuthenticatedWrapper from "src/pages/Account/account";
import Chat from "src/pages/Chat";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const authRoutes: Array<RouteProps> = [
  //dashboard
  { path: "/home", component: <Dashboard /> },

  // { path: "/chat", component: <Chat /> },

  { path: "/thank-you", component: <ThankYou /> },

  { path: "/pricing", exact: true, component: <Paywall isSubscribed={false} /> },

  { path: "/lessons", component: <Dashboard /> },

  { path: "/lesson/:lessonId/:lessonTitle", component: <Lesson /> },

  { path: "/lesson/:lessonId/:lessonTitle/:subtopicId/:subtopicTitle", component: <LessonDetails /> },

  { path: "/", exact: true, component: <Navigate to="/home" /> },
];

const nonAuthRoutes: Array<RouteProps> = [
  { path: "/auth", exact: true, component: <AuthenticatedWrapper /> },

  { path: "/pricing-non-auth", exact: true, component: <PagePricing /> },

  { path: "/blog", component: <Blog /> },

  { path: "/articles/:articleId/", component: <ArticleDetails /> },

  { path: "/faq", exact: true, component: <PageFaqs /> },

  { path: "/privacy-policy", exact: true, component: <PrivacyPolicy /> },

  { path: "/tos", exact: true, component: <TOS /> },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Dashboard /> },
];

export { authRoutes, nonAuthRoutes };
